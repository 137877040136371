.login {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(circle at 50% 50%, #313264 0%, #00023b 60%, #00023b 100%);
  background-repeat: no-repeat;
}

.login .card {
  width: 24rem;
  display: block;
  background: white;
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.login .card > div {
  text-align: center;
}

.login form div {
  padding: 0.25rem 0;
}

.login form input {
  padding: 0.5rem 0.25rem;
  border: 1px solid black;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
}

.login form button {
  border-radius: 8px;
  padding: 0.5rem;
  appearance: none;
  border: none;
  background: #3f51b5;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
}
